import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import main_car_1_img_1 from '../new_images/main/main_car_1_img_1.png'
import main_car_1_img_2 from '../new_images/main/main_car_1_img_2.png'
import main_car_1_img_3 from '../new_images/main/main_car_1_img_3.png'
import main_car_1_img_4 from '../new_images/main/main_car_1_img_4.png'
import main_car_1_img_5 from '../new_images/main/main_car_1_img_5.png'
import main_car_1_img_6 from '../new_images/main/main_car_1_img_6.png'
import main_car_1_img_7 from '../new_images/main/main_car_1_img_7.png'
import main_car_2_img_1 from '../new_images/main/main_car_2_img_1.png'
import main_car_2_img_2 from '../new_images/main/main_car_2_img_2.png'
import main_car_2_img_3 from '../new_images/main/main_car_2_img_3.png'
import main_car_2_img_4 from '../new_images/main/main_car_2_img_4.png'
import main_car_2_img_5 from '../new_images/main/main_car_2_img_5.png'
import main_car_2_img_6 from '../new_images/main/main_car_2_img_6.png'
import main_second from '../new_images/main/main_second.png'
import main_third_icon from '../new_images/main/main_third_icon.png'
import main_third_icon_white from '../new_images/main/main_third_icon_white.png'
import main_third_icon_1 from '../new_images/main/main_third_icon_1.png'
import main_third_icon_2 from '../new_images/main/main_third_icon_2.png'
import main_third_icon_3 from '../new_images/main/main_third_icon_3.png'
import main_third_icon_1_white from '../new_images/main/main_third_icon_1_white.png'
import main_third_icon_2_white from '../new_images/main/main_third_icon_2_white.png'
import main_third_icon_3_white from '../new_images/main/main_third_icon_3_white.png'
import { getfaqHttp } from '../host/requests/User';
import FooterMobile from '../components/FooterMobile';

const settings = {
  dots: false,       // Disable dots
  infinite: true,    // Enable infinite scrolling
  speed: 3000,        // Transition speed
  slidesToShow: 3,   // Number of slides to show
  slidesToScroll: 1, // Number of slides to scroll
  vertical: true,    // Vertical scrolling
  autoplay: true,    // Autoplay enabled
  autoplaySpeed: 1, // Autoplay interval
  verticalSwiping: false, 
  cssEase: 'ease-in-out'
};
const settings1 = {
  dots: false,       // Disable dots
  infinite: true,    // Enable infinite scrolling
  speed: 3000,        // Transition speed
  slidesToShow: 3,   // Number of slides to show
  slidesToScroll: 1, // Number of slides to scroll
  vertical: true,    // Vertical scrolling
  autoplay: true,    // Autoplay enabled
  autoplaySpeed: 1, // Autoplay interval
  verticalSwiping: true, 
  cssEase: 'ease-in-out'
};
export default function Dashboard() {
    const {t} =useTranslation()
    useEffect(()=>{
         getFaqsData();
    }, [])
    const getFaqsData=async()=>{
     try{
      var res= await getfaqHttp({page:1})
       console.log(res)
    }catch(err){
      console.log(err)
     }
      
    }
  return (
    <>
    <div className='landing_page pc_page'>
            <div className='dash_main'>
              <div className='dash_main_text'>
                <p dangerouslySetInnerHTML={{__html:t("main_head_text")}}/>
                <span>{t("main_text")}</span>
                <div className='btn_box'>
                <Link className='btn_main' to={"/login"}>{t("login")}</Link>
                <Link className='btn_border' to={"/aboutus"}>{t("aboutus")}</Link>
                </div>
              </div>
              <div className='dash_main_imgs'>
              <div className='dash_car dash_car_1'>
                <Slider {...settings}>
              <div>
    <div className='main_img_item'><img src={main_car_1_img_1}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_2}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_3}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_4}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_5}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_6}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_1_img_7}/></div>
  </div>
</Slider>
                </div>
                <div className='dash_car dash_car_2'>
                <Slider {...settings1}>
              <div>
    <div className='main_img_item'><img src={main_car_2_img_1}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_2_img_2}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_2_img_3}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_2_img_4}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_2_img_5}/></div>
  </div>
  <div>
    <div className='main_img_item'><img src={main_car_2_img_6}/></div>
  </div>
</Slider>
                </div>
             
              </div>
            </div>
            <div className='main_second'>
              <div className='main_second_text'>
                <h1>{t("main_second_head")}</h1>
                <p>{t("main_second_text")}</p>
              </div>
              <div className='main_second_img'>
                <img src={main_second}/>
              </div>
            </div>
            <div className='main_third'>
              <div className='main_third_head'>
                <div className='main_third_head_head'>
                  <div className='image_box'>
                  <img className='light_img' src={main_third_icon}/>
                  <img className='dark_img' src={main_third_icon_white}/>
                  </div>
                  
                  <h1>{t("main_third_head")}</h1>
                </div>
                <p dangerouslySetInnerHTML={{__html:t("main_third_text")}} />
              </div>
              <div className='main_third_row'>
              <div className='main_third_col'>
                <div className='main_third_card'>
                <div className='image_box'>
                  <img className='light_img' src={main_third_icon_1}/>
                  <img className='dark_img' src={main_third_icon_1_white}/>
                  </div>
                   
                    <h1>{t("main_third_card_1_head")}</h1>
                    <p>{t("main_third_card_1_text")}</p>
                  </div>
                </div>
                <div className='main_third_col'>
                <div className='main_third_card'>
                <div className='image_box'>
                  <img className='light_img' src={main_third_icon_2}/>
                  <img className='dark_img' src={main_third_icon_2_white}/>
                  </div>
                   
                    <h1>{t("main_third_card_2_head")}</h1>
                    <p>{t("main_third_card_2_text")}</p>
                  </div>
                </div>
                <div className='main_third_col'>
                <div className='main_third_card'>
                <div className='image_box'>
                  <img className='light_img' src={main_third_icon_3}/>
                  <img className='dark_img' src={main_third_icon_3_white}/>
                  </div>
                 
                    <h1>{t("main_third_card_3_head")}</h1>
                    <p>{t("main_third_card_3_text")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='main_ques'>
                 <h1>{t("has_ques")}</h1>
                 <p>{t("has_ques_text")}</p>
                 <Link className='btn_main' to={"/support"}>{t("contact")}</Link>
            </div>
            <div className='main_find'>
              <div className='main_find_text'>
              <h1>{t("find_love")}</h1>
                 <p>{t("find_love_text")}</p>
                 <Link className='btn_main' to={"/login"}>{t("login")}</Link>
              </div>
            </div>
           </div>
           <div className='mobile_page mobile_main'>
            <div className='mobile_main_text'>
            <p dangerouslySetInnerHTML={{__html:t("find_love_phone")}}></p>
                <h1>{t("you")}</h1>
                <div className='btns_box'>
                  <Link className='mobile_btn' to={"/login"}>{t("woman")}</Link>
                  <Link className='mobile_btn' to={"/login"}>{t("man")}</Link>
                </div>
                <FooterMobile/>
            </div>
              
           </div>
           </>
  )
}
