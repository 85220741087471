import React, { useEffect, useState } from 'react'
import { BsQuestionOctagonFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useStore } from '../store/Store'
import { getfaqHttp } from '../host/requests/User'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import i18n from '../locale/i18next'

export default function Setting() {
  const setisBlue=useStore(state=>state.setisBlue)
  const isBlue=useStore(state=>state.isBlue)
  const {t}=useTranslation()
  const [cookie, setCookie]=useCookies()
  const [til, settil] = useState(cookie.tilTanish?cookie.tilTanish:"uz");
  const changeLang=(lang)=>{
    
      i18n.changeLanguage(lang)
      settil(lang)
      setCookie("tilTanish", lang)
    
}

  return (
    <div className='setting_box'>
          <h1 className='setting_title'>{t("change_color")}</h1>
          <div className='gender_big_box'>
     
      <div className='gender_box'>
      <div onClick={()=>{setisBlue(true)}} className={`gender_item ${!isBlue?"active_gender":''}`}>
          <div className='gender_text'>{t("light_color")}</div>
          <div className={`gender_check`}></div>
        </div>
        <div onClick={()=>{setisBlue(false)}} className={`gender_item ${isBlue?"active_gender":''}`}>
          <div className='gender_text'>{t("dark_color")}</div>
          <div className={`gender_check`}></div>
        </div>
      </div>
      </div>
      <h1 className='setting_title'>{t("change_lang")}</h1>
          <div className='gender_big_box'>
     
      <div className='gender_box'>
      <div onClick={()=>{changeLang('uz')}} className={`gender_item ${til=='uz'?"active_gender":''}`}>
          <div className='gender_text'>UZ</div>
          <div className={`gender_check`}></div>
        </div>
        <div onClick={()=>{changeLang('ru')}} className={`gender_item ${til=='ru'?"active_gender":''}`}>
          <div className='gender_text'>РУ</div>
          <div className={`gender_check`}></div>
        </div>
        <div onClick={()=>{changeLang('en')}} className={`gender_item ${til=='en'?"active_gender":''}`}>
          <div className='gender_text'>EN</div>
          <div className={`gender_check`}></div>
        </div>
      </div>
      </div>
    </div>
  )
}
