import React, { useState } from 'react'
import { BsCheckLg, BsCheckAll, BsPlusCircleFill, BsCheck } from "react-icons/bs";
import { useTranslation } from 'react-i18next'
import { TbLock, TbLockOpen, TbPlayerPause, TbTrashFilled } from "react-icons/tb";
import { FaArrowDown, FaPlay, FaStop, FaTelegramPlane, FaTrash } from "react-icons/fa";
import { FaImage, FaVideo } from "react-icons/fa";
import { useEffect } from 'react';
import { useRef } from 'react';
import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../store/Store';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { checkDate, old_now } from '../components/Checkers';
import { AiOutlineLoading } from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { blockUser, deleteUserBlock, getBlockReasons, getChatsHttp, getChatUsers, getUserById, getUserForChat } from '../host/requests/User';
import { Button, Form, Image, Modal, Select } from 'antd';
import { IoIosSend, IoIosWarning } from "react-icons/io";
import axios from 'axios';
import { apiEn } from '../host/Host';
import { dangerAlert, successAlert } from '../components/Alert';
import ReactPlayer from 'react-player';
import { PuffLoader  } from 'react-spinners';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { FaHeart, FaRegCreditCard, FaRegHeart } from 'react-icons/fa6';

export default function Chat({chat_id, open_active, setopen_active}) {
  const {t} =useTranslation()
  const [form] = Form.useForm();
  const chat_body=useRef()
  const token_access =useStore(state=>state.token_access)
  const setis_online =useStore(state=>state.setis_online)
  const is_online =useStore(state=>state.is_online)
  
  const setchat_number =useStore(state=>state.setchat_number)
  const chat_number =useStore(state=>state.chat_number)
  const setis_typing =useStore(state=>state.setis_typing)
  const setopen_chat=useStore(state=>state.setopen_chat)
  const setchat_data=useStore(state=>state.setchat_data)
  const setchatsfirst=useStore(state=>state.setchatsfirst)
  const is_typing =useStore(state=>state.is_typing)
  const setchat_count =useStore(state=>state.setchat_count)
  const chat_count =useStore(state=>state.chat_count)
  const delete_chat =useStore(state=>state.delete_chat)
  const delete_dark_list =useStore(state=>state.delete_dark_list)
  const open_chat =useStore(state=>state.open_chat)
  const chat_data =useStore(state=>state.chat_data)
  const addusersrandom =useStore(state=>state.addusersrandom)
  const user =useStore(state=>state.user)
  const blockReasons =useStore(state=>state.blockReasons)
  const setblockReasons =useStore(state=>state.setblockReasons)
  const setloader =useStore(state=>state.setloader)
  const editchats=useStore(state=>state.editchats)
  const number=useStore(state=>state.number)
  const changeLastLogin=useStore(state=>state.changeLastLogin)
  const navigate=useNavigate()
  const [message, setMessage] = useState('');
  const [video_keys, setvideo_keys] = useState([]);
  const [open_btns, setopen_btns] = useState(false);
  const [need_activation, setneed_activation] = useState(false);
  const [vi_number, setvi_number] = useState(0);
  const [video_links, setvideo_links] = useState([]);
  const [chat_next, setchat_next] = useState('a');
  const [last_login, setlast_login] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [deleted_messages, setdeleted_messages] = useState('');
  const [deleted_bol, setdeleted_bol] = useState(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(false);
  const [last_message, setlast_message] = useState(null);
  const [un_read, setun_read] = useState([]);
  const [chat_spin, setchat_spin] = useState(false);
  const [blockModal, setblockModal] = useState(false);
  const [videoModal, setvideoModal] = useState(false);
  const [chat_page_num, setchat_page_num] = useState(1);
  const [sended_images, setsended_images] = useState(false);
  const [sended_video, setsended_video] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  const videoRef = useRef(null);
  const [recording, setRecording] = useState(false);
  const [send_video, setsend_video] = useState(false);
  const [stop_play, setstop_play] = useState(true);
  const [videoBlob, setVideoBlob] = useState(null);
  const [playingIndex, setPlayingIndex] = useState(null);
 const location =useLocation()
  var time=null
  var g=true
  const videoRefs = useRef([]);


  useEffect(()=>{
    setopen_active(false)
    setvideo_keys([])
    setvi_number(0)
    setsended_video(false)
    setRecording(false)
    setsended_images(false)
    setstop_play(true)
    setVideoBlob(null)
    setneed_activation(false)
    setPlayingIndex(null)
    setlast_login(null)
    setopen_btns(false)
    setvideo_links([])
    setdeleted_messages('')
    setMessageHistory([])
     setchat_next('a')  
     setchat_page_num(1)
     getChatUsers({name:chat_id}, token_access).then((res)=>{
       if(res.data.results!=null && res.data.results.length>0){
          setopen_chat(res.data.results[0])
          var g_chat=res.data.results[0]
          if(g_chat==null){
            navigate("/cabinet/chat/all")
          }else{
           setchat_data(
             g_chat.data["user-activation"] 
             ? g_chat.data["user-activation"] 
             : { [user.id] : { limit: 5, type: "MessageLimit" } }
         );
         setchat_number(chat_number+1)
           if(g_chat.data!={} && g_chat.data["user-activation"] && g_chat.data["user-activation"][user.id]){
            setneed_activation(false)   
            if((g_chat.data["user-activation"][user.id].type==="MessageLimit" && g_chat.data["user-activation"][user.id].limit===0)){
               setneed_activation(true)
               }
                if((g_chat.data["user-activation"][user.id].type==="TarifActivation" && g_chat.data["user-activation"][user.id].expired_at)){
                  const dateStr =g_chat.data["user-activation"][user.id].expired_at.trim();
                  const [datePart, timePart] = dateStr.split(" ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    const now = new Date();
    
                  const isAfterNow = date > now; 
                  console.log(date, now, isAfterNow)
                  if(!isAfterNow){
                    setneed_activation(true)
                  }else{
                    setneed_activation(false)
                  }
              }  
           }else{
            setneed_activation(false)
          }
           setis_online(g_chat.is_online)
           if(blockReasons===null){
             getBlockReasonsFunc()
           }
           setlast_login(g_chat.other_user.last_login)
          }
       }else{
        navigate("/cabinet/chat/all")
       }
     }).catch((err)=>{
      console.log(err)
     })
   }, [location]);
   


const { sendJsonMessage, lastMessage, readyState } = useWebSocket( `wss://api.bez-komplus.com/ws/chats/${chat_id}/`,
  {
       queryParams: {
              token: token_access,
            },
            onOpen: () => {
           
              console.log("Connected!");
            },
            onClose: (error) => {
             
              console.log("Disconnected!", error);
            },
            onMessage: (e) => {
              const data = JSON.parse(e.data);
              console.log(data)
              switch (data.type) {
                case "chat_message_echo":
                  setMessageHistory((prev) => [...prev, data.message]);
                  var a=data.message.id
                  if(data.message.from_user!==user.id){
                    if(chat_body.current.scrollHeight!==chat_body.current.clientHeight){
                      setun_read([...un_read, a])
                    }
                      
                    }
                   
                 break;
                 case "need_activation":
                  if(data.name===user.chat_id){
                    setneed_activation(true)
                    var t=chat_data
                    t[user.id]={
                        limit:0,
                        type:"MessageLimit"
                    }
                    setchat_data(t)
                    setchat_number(chat_number+1)
                    openTarifModal()
                   }
                  
                   if(data.name===open_chat.other_user.chat_id){
                   var t=chat_data

                    t[open_chat.other_user.id]={
                        limit:0,
                        type:"MessageLimit"
                    }
                    console.log(t)
                    setchat_data(t)
                    setchat_number(chat_number+1)
                   }
                 break;
                 case "activation_completed":
                  if(data.name===user.chat_id){
                    setneed_activation(false)
                    var t=chat_data
                    t[user.id]={
                      expired_at:data.expired_at,
                      type:"TarifActivation"
                    }
                    setchat_data(t)
                    setchat_number(chat_number+1)
                    // window.location.href=window.location.href
                   }
                  
                   if(data.name===open_chat.other_user.chat_id){
                   var t=chat_data

                    t[open_chat.other_user.id]={
                        expired_at:data.expired_at,
                      type:"TarifActivation"
                    }
                   
                    setchat_data(t)
                    setchat_number(chat_number+1)
                   }
                 break;
                 case "online_user_list":
                  if(data.users.filter(x=>x===open_chat.other_user.chat_id).length>0){
                    setis_online(true)
                    setlast_login(Date())
                    changeLastLogin(data.user)
                  }
                  break;
                  case "message_deleted":
                    var a=deleted_messages
                    setdeleted_messages(a+","+data.message)
                    break;
                    case "read_messages":
                    
                     var oldA=messageHistory.map((item)=>{
                      var gb=data.read_messages.filter(x=>x===item.id)
                      if(gb.length!==0){
                          item.read=true
                      }
                      return(item)
                     })
                     setMessageHistory(oldA)
                      break;
                      case "message_reacted":
                    
                      var a=messageHistory.map((item, key)=>{
                        if(item.id==data.message){
                          item.liked=data.liked
                        }
                        return(item)
                      })
                      setMessageHistory(a)
                       break;
                    case "user_join":
                      if(!open_chat.blocked_me && data.user===open_chat.other_user.chat_id){
                        setis_online(true)
                        setlast_login(Date())
                        changeLastLogin(data.user)
                      }

                       break;
                      case "user_leave":
                        setis_online(false)
                        setlast_login(Date())
                    changeLastLogin(data.user)
                       break;
                case "last_50_messages":
                 
                     var a=[...data.messages].reverse()
                     
                  setMessageHistory(a);
                  console.log(data.has_more, "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
                  setHasMoreMessages(data.has_more);
                  if(a.length!==0){
                    setlast_message(a[0].id)
                    var f=data.messages.filter(x=>!x.read && x.to_user===user.id).map(item=>item.id)
                  sendJsonMessage({
                    "type": "read_messages",
                    "read_msg_ids": f
                });
                if(chat_count-f.length>=0){
                 setchat_count(chat_count-f.length)
                }else{
                   setchat_count(0)
                }
                editchats(open_chat, open_chat.name, -1*f.length)
                  }
                  setTimeout(() => {
                    scrollToBottom()
                    setloader(false)
                  }, 50);
                  
                
                  break;
                  case "typing":
                    setis_typing(data.user!==user.chat_id?data.typing:false)
                    setlast_login(Date())
                    changeLastLogin(data.user)
                  break;
                default:
                  console.error(data);
                  break;
              }
            },
           
  });
const openTarifModal=()=>{
  modal.confirm({
    title: t("alert"),
    icon: <span className='warning_icon'><IoIosWarning /></span>,
    content: t("active_modal_text"),
    okText: t("activate_plan"),
    cancelText: t("after_time"),
    onOk:()=>{navigate('/cabinet/plans/'+open_chat.id+"/"+user.id+"/"+open_chat.name)},
   });
}
  const resizeImage = (image) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      const reader = new FileReader();
  
      reader.onload = (e) => {
        img.src = e.target.result;
  
        img.onload = () => {
          let width = img.width;
          let height = img.height;
  
          // Resize only if width is greater than 400
          if (width > 550) {
            const aspectRatio = height / width; // Maintain aspect ratio
            width = 550;
            height = width * aspectRatio; // Adjust height proportionally
          }
  
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          canvas.width = width;
          canvas.height = height;
  
          // Draw the resized image onto the canvas
          ctx.drawImage(img, 0, 0, width, height);
  
          // Convert canvas content back to a blob
          canvas.toBlob((blob) => {
            resolve(blob);
          }, "image/jpeg", 0.8); // Set JPEG quality to 80%
        };
      };
  
      reader.readAsDataURL(image);
    });
  };
  const sendImage=async(im)=>{
    setsended_images(true)
    var data=new FormData()
    if(im.size <= 90 * 1024){
      data.append("file", im)
      data.append("conversation", open_chat.name)
      axios.post(`${apiEn}/en/api/chat/messages/uploads/`, data, {
        headers:{
            "Authorization":"Bearer "+token_access
        }
    }).then(res=>{
      console.log(res)
      sendJsonMessage({
        type: "chat_message",
        message:" ",
        file:res.data.file_path
      });
      setsended_images(false)
      setopen_btns(false)
    }).catch(err=>{
      dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      setloader(false)
      setsended_images(false)
    })
    }else{
    
          const resizedImage = await resizeImage(im);
          
        data.append("file", resizedImage)
        data.append("conversation", open_chat.name)
        axios.post(`${apiEn}/en/api/chat/messages/uploads/`, data, {
          headers:{
              "Authorization":"Bearer "+token_access
          }
      }).then(res=>{
        console.log(res)
        sendJsonMessage({
          type: "chat_message",
          message:" ",
          file:res.data.file_path
        });
        setsended_images(false)
        setopen_btns(false)
      }).catch(err=>{
        dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
        setloader(false)
        setsended_images(false)
      })
    
    }
  
  }
  const sendVideo=async()=>{
    setvideoModal(false)
    setsended_video(true)
 
      var data=new FormData()
      data.append("file", videoBlob , Math.random()+open_chat.name+'.webm')
      data.append("conversation", open_chat.name)
      axios.post(`${apiEn}/en/api/chat/messages/uploads/`, data, {
        headers:{
            "Authorization":"Bearer "+token_access
        }
    }).then(res=>{
     console.log(res.data.file_path)
      sendJsonMessage({
        type: "chat_message",
        message:" ",
        file:res.data.file_path
      });
      setopen_btns(false)
      setsended_video(false)
     closeVideoModal()
     
    }).catch(err=>{
      dangerAlert("Texnik nosozlik yuzaga keldi. Keyinroq qaytib urinib ko'ring")
      setsended_video(false)
     })

   
  }
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

const getBlockReasonsFunc=async()=>{
  try{
    var res=await getBlockReasons(token_access)
    setblockReasons(res.data)
  
  }catch(error){
console.log(error)
  }
}

 
  const scrollToBottom=()=>{
    chat_body.current.scrollTop = chat_body.current.scrollHeight;
  }

  const sendMessage=()=>{
    console.log(message)
       if(message!==null && message.length!==0){
              var str=message
              let start=0
              let stop=400
              while(str.slice(start, stop).length!=0){
                console.log(message)
                sendJsonMessage({
                  type: "chat_message",
                  message:str.slice(start, stop),
                });
                start+=400
                stop+=400
              }
              
              setMessage("")
              sendJsonMessage({
                type: "typing",
                typing:false
              })
              setTimeout(() => {
                scrollToBottom()
              }, 400);
            }
  }

const getChats=async()=>{
  setloader(true)
  var scrollH=chat_body.current.scrollHeight-chat_body.current.scrollTop
  
 try{
    var res=await getChatsHttp({
        conversation:chat_id,
         last_message:last_message,
         page:chat_page_num
}, token_access)
var a=res.data.results.reverse()
console.log(res.data.next, "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL")
setchat_next(res.data.next)
setMessageHistory((prev) => [...a ,...prev]);
setchat_page_num(chat_page_num+1)
setTimeout(() => {
  chat_body.current.scrollTop = chat_body.current.scrollHeight-scrollH-50;
  setloader(false)
}, 50);
var f=res.data.results.reverse().filter(x=>!x.read && x.to_user===user.id).map(item=>item.id)
sendJsonMessage({
  "type": "read_messages",
  "read_msg_ids": f
});
if(chat_count-f.length>=0){
  console.log("Hello2")
setchat_count(chat_count-f.length)
}else{
  setchat_count(0)
}
editchats(open_chat, open_chat.name, -1*f.length)
 }catch(error){
  // console.log(error)
  setloader(false)
 }
  
}
const deleteChat=(id, type)=>{
  console.log(id)
  sendJsonMessage({
    "type": "delete_message",
    "message": id
});
var a=deleted_messages
setdeleted_messages(a+","+id)
if(type==='video'){
  setPlayingIndex(null)
}


}
const onChangeScroll=()=>{
  if(un_read.length!==0){
    sendJsonMessage({
      "type": "read_messages",
      "read_msg_ids": un_read
  });
  if(chat_count-un_read.length>=0){
    console.log("Hello3")
  setchat_count(chat_count-un_read.length)
  }else{
      setchat_count(0)
  }
  editchats(open_chat, open_chat.name, -1*un_read.length)
  setun_read([]) 
}
 
}
const checkScrollBottom=()=>{
  const isAtBottom = Math.abs((chat_body.current.scrollHeight - chat_body.current.scrollTop) - chat_body.current.clientHeight)<40
  
  if(isAtBottom){
    onChangeScroll()
  }
}
const openModalConfirm=()=>{

  modal.confirm({
      title: t("alert"),
      icon: <span className='warning_icon'><IoIosWarning /></span>,
      content: t("delete_chat"),
      okText: t("yes"),
      cancelText: t("no"),
      onOk:()=>{deleteData()},
     });
}
const deleteData=async()=>{
    axios.delete(`${apiEn}/en/api/chat/conversations/delete/${chat_id}/`, {
      headers:{
          "Authorization":"Bearer "+token_access
      }
  }).then(res=>{
      successAlert(t("chat_deleted"))

      navigate("/cabinet/chat/all")
    })
}
const getTime=(date)=>{
  if(date!==null){
    const originalTimestamp = new Date(date);
    const newTimestamp = new Date(originalTimestamp.getTime() + 5 * 60 * 60 * 1000);
    return(newTimestamp.toISOString().slice(11, 16))
  }else{
    return("")
  }
  
}
const openblockUserByUser=()=>{
  setblockModal(true)
}

const handleCancel=()=>{
  setblockModal(false)
  form.setFieldsValue({
    reason: null
  });
}
const onFinish=async(val)=>{
  var config={
    user:open_chat.other_user.id,
    reason:val.reason
  }
  setloader(true)
  try{
    var res=await blockUser(config, token_access)
    setis_typing(false)
    var gy=blockReasons.filter(x=>x.id===val.reason)

    delete_chat(open_chat.name, gy[0], res.data.id)
    successAlert(t("user_blocked"))
    handleCancel()
    setloader(false)
   
   }catch(error){
    dangerAlert(t("user_unblocked"))
    setloader(false)
  }
  

}
const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())

const startCamera = async () => {
  try {
    // Access the user's camera
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio:true });

    // Set the video source to the camera stream
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    setstop_play(false)
  } catch (error) {
    console.error('Error accessing camera:', error);
    dangerAlert(t("permit_video"))
    setvideoModal(false)
  }
};
const openVideoModal=()=>{
  startCamera()
  setvideoModal(true)
  
}
const closeVideoModal=()=>{
  setvideoModal(false)
  stopCamera()
  document.querySelector(".sec_ten").innerHTML=0
  document.querySelector(".sec_one").innerHTML=0
}
const delete_bol_func=(id, type)=>{
  if(deleted_bol===id){
    if(type==="text"){
      setdeleted_bol(null)
    }
  }else{
    setdeleted_bol(id)
  }
}
const startRecording = () => {

  if (videoRef.current && videoRef.current.srcObject) {
    const mediaRecorder = new MediaRecorder(videoRef.current.srcObject);
    const chunks = [];

    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunks.push(event.data);
      }
    };

    mediaRecorder.onstop = () => {
      const videoBlob = new Blob(chunks, { type: 'video/webm' });
      var st=document.querySelector('.videoModal').innerHTML
      if(st==='true'){
       setVideoBlob(videoBlob);
        setsend_video(true)
      }
      
    };

    mediaRecorder.start();
var x=1
   var tim=setInterval(()=>{
    document.querySelector(".sec_ten").innerHTML=parseInt(x/10)
    document.querySelector(".sec_one").innerHTML=x%10
    var st=document.querySelector('.stopVideo').innerHTML
   if(st==='false' || x===30){
    mediaRecorder.stop();
    setRecording(false);
    clearInterval(tim)
   }

   x++ 
 
  }, 1000)
  setstop_play(true)
  setRecording(true);
  }
};
const stopRecording = () => {
  setRecording(false);
};
const stopCamera = () => {
  if (videoRef.current) {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
    }
    videoRef.current.srcObject = null;
  }
  setVideoBlob(null)
  setRecording(false);
  setsend_video(false)
 
};
const deleteBlock=async(id)=>{
  setloader(true)
      try{
        var res=await deleteUserBlock(id, token_access)
        successAlert(t("block_open"))
        delete_dark_list(id)
        setloader(false)
        goToChat()
        
      }catch(error){
        dangerAlert(t("block_unopen"))
        setloader(false)
      }
}
const editVideoLink=(index)=>{
    var a=video_links
    a[index]=1
    setvideo_links(a)
    console.log(a)
    setvi_number(vi_number+1)
}
const goToChat=async()=>{
    
 var chat_name=[user.chat_id, open_chat.other_user.chat_id].sort()[0]+"__"+[user.chat_id, open_chat.other_user.chat_id].sort()[1]
var res = await getUserForChat({user:open_chat.other_user.id}, token_access)
 setchatsfirst(res.data)
 setopen_chat(res.data)
 setloader(false)
 navigate("/cabinet/chat/"+chat_name)
} 
const getUserOne=async(id)=>{
  try{
    setloader(true)
    var res=await getUserById({pk:id}, token_access)
     addusersrandom(res.data)
     setloader(false)
  }catch(error){
    console.log(error)
    setloader(false)
  }
}
const playVideoFunc = (index) => {
    var a=video_keys
    var b=a[index]
    console.log(index)
    a.fill(false)
    if(b){
        a[index]=false
        setPlayingIndex(null)
    }else{
       videoRefs.current[index].seekTo(0)
       a[index]=true
       setPlayingIndex(index)
    }
    setvideo_keys(a)
    setvi_number(vi_number+1)
   console.log(a)
    
};

const pauseVideoFunc = (index) => {
    var a=video_keys
    
    a.fill(false)
    setvideo_keys(a)
    setvi_number(vi_number+1)
    setPlayingIndex(null)
};
const handleVideoStart=(index)=>{
  var a=video_links
  a[index]=2
  setvideo_links(a)
  console.log(a)
  setvi_number(vi_number+1)
}

const likeChat=(id, liked)=>{
  sendJsonMessage({
    type: "react_message",
    message:id,
    liked:!liked
  });
  var a=messageHistory.map((item, key)=>{
    if(item.id===id){
      item.liked=!liked
    }
    return(item)
  })
  setMessageHistory(a)
}
  return (
    <>
    <p className='stopVideo' style={{display:"none"}}>{`${recording}`}</p>
    <p className='videoModal' style={{display:"none"}}>{`${videoModal}`}</p>
    <p style={{display:"none"}}>{`${number}`}</p>
    <Modal title={t("block_modal_title")} open={blockModal} footer={false} onCancel={handleCancel}>
      <br/>
    <Form
    name="form"
    form={form}
   
    labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      initialValues={{ remember: false }}
      autoComplete='new-password'
  >
<Form.Item
    hasFeedback
      label={""}
      name="reason"
      rules={[
        {
          required: true,
          message: t("reason_error")
        },
      ]}
    >
    <Select
    showSearch
    optionFilterProp="children"
    filterOption={filterOption}
    options={blockReasons!==null?blockReasons.map((item, ket)=>({label:item[`reason_${t("error_api")}`], value:item.id})):[]}
    />
    </Form.Item>
    <div className='btnBox'>
        <Button type='primary' htmlType="submit">
          {t("block")}
        </Button>
        </div>
  </Form>
  
    </Modal>
    <Modal open={videoModal} className='video_sended_modal' footer={false} onCancel={closeVideoModal}>
      <div className='chat_video_box'>
        <div className='video_time'>
          <span>0</span>
          <span>0</span>
          <span>:</span>
          <span className='sec_ten'>0</span>
          <span className='sec_one'>0</span>

        </div>
      <div className='chat_video'> 
    <video ref={videoRef} muted autoPlay playsInline />
    </div>
    <div className='chat_video_btns'>
      {recording?<button className='video_stop' onClick={stopRecording}><FaStop /></button>:
      send_video?<button className='video_send' onClick={sendVideo}><IoIosSend /></button>:
      !stop_play?<button className='video_play' onClick={startRecording}><FaPlay /></button>:<></> }
      
      
      
    </div>
    </div>
    </Modal>
 {open_chat!==null && user!==null? <div className='chat'>
      <div className='chat_back'></div>
            <div className='chat_header'>
              <div className='chat_header_left'>
            <Link to={"/cabinet/chat/all"} className='back_icon_chat'><MdOutlineArrowBackIosNew /></Link>
              <div className='chat_header_user' onClick={()=>{navigate("/cabinet/user/"+open_chat.other_user.id)}}>
            
       <div className='avatar_box' style={{backgroundImage:`url(${open_chat.other_user!==null && open_chat.other_user.main_image!==null?open_chat.other_user.main_image:open_chat.other_user.gender=='male'?man_icon:woman_icon})`}}></div>
      
       <div className='username'>
               <h1>{open_chat.other_user.first_name}</h1>
               <p>{open_chat.blocked_me?old_now[t('error_api')]:is_typing?t("typing..."):is_online?t("online"):checkDate(last_login, t("error_api"))}</p>
          </div>
          </div>
          </div>
         <div className='chat_drop'>
          {playingIndex!==null?<span onClick={()=>{pauseVideoFunc(playingIndex)}}><TbPlayerPause /></span>:<></>}
         
         <span onClick={()=>{if(open_chat.me_blocked){deleteBlock(open_chat.block_id)}else{openblockUserByUser()}}}>{open_chat.me_blocked?<TbLock/>:<TbLockOpen />}</span>
         <span onClick={openModalConfirm}><TbTrashFilled /></span>
         <span className='mobile_icon' onClick={()=>{setopen_active(!open_active)}}><FaRegCreditCard /></span>
         </div>
            </div>
            <div ref={chat_body} onClick={()=>{setopen_active(false)}} className={`chat_body ${messageHistory.length===0?"empty_chat":''}`} onScroll={checkScrollBottom}>
          
            {(hasMoreMessages && chat_next!==null)?
       <div onClick={()=>{getChats()}} className='addBtnBox'>
       {chat_spin?<button className='loadBtn'><AiOutlineLoading /></button>: <button><GoPlus/></button>}
      
     </div>
      :<></>}
           {
            messageHistory.map((item,key)=>{
             if(deleted_messages.indexOf(","+item.id)===-1){
            var oldTime=item.timestamp.slice(0, item.timestamp.indexOf("T"))
            oldTime=oldTime.slice(8,10)+"-"+oldTime.slice(5,7)+"-"+oldTime.slice(0,4)
            if(oldTime!==time){
              time=oldTime
              g=true
            }else{
              g=false
            }
              if(item.from_user===user.id){
                return(<>
                {g?<div className='time_box'>
                 <div className='time_item'>{time}</div>
                </div>:<></>}
                
                <div key={key} onClick={()=>{delete_bol_func(item.id, item.file===null?"text":item.file.indexOf('.mp4')!==-1 || item.file.indexOf('.webm')!==-1?"video":"image")}} className='chat_massege self_massege'>
                  
                <div className='message_text'>
                {
                item.id===deleted_bol?<><div onClick={()=>{deleteChat(item.id, item.file===null?"text":item.file.indexOf('.mp4')!==-1 || item.file.indexOf('.webm')!==-1?"video":"image")}} className='deleted_chat'>
                <TbTrashFilled />
                </div>
               
                </>:<></>
               } 
                 {item.file===null?item.content:item.file.indexOf('.mp4')!==-1 || item.file.indexOf('.webm')!==-1?
         <div className='chat_video_chat' id={key===playingIndex?"play_video":""}>
          {video_links[key]?
         
          <>
         {video_links[key]==1?<div className='video_load'><PuffLoader   size={100} color="white" /></div>:<></>}
            <div onClick={()=>{playVideoFunc(key)}} id={key===playingIndex?"play_video":""} className='play_video_box'></div>
          <ReactPlayer
  controls={false}
  config={{
    file: { forceHLS: false },
    attributes: { playsInline: true }
  }}
  onReady={()=>{handleVideoStart(key)}}
  className="video_ch"
  playing={video_keys[key]}
  url={item.file}
  ref={(el) => (videoRefs.current[key] = el)}
/>
          </>:<div onClick={()=>{editVideoLink(key)}} className='video_download'></div>}
         
         </div>
         :<div className='chat_image'><Image  preview={{ mask: false,  movable: false  }} movable={false} className='chat_image_image' src={item.file}/></div>}
                {item.liked? <span className='like_icon'>
                   <FaHeart />
                  </span>:<></>}
                 <p className='chat_time'>
                
                 <span>{getTime(item.timestamp)}</span>
                 <span className='chat_time_icon'>{item.read?<BsCheckAll />:<BsCheck />}</span>
                 
                 </p>
                </div>
                <div className='avatar'>
                <div className='avatar_box' style={{backgroundImage:`url(${user!==null && user.main_image!==null?user.main_image.media:user.gender=='male'?man_icon:woman_icon})`}}></div>
                </div>
                       </div></>)
                
               }else if(item.from_user===open_chat.other_user.id){
                return( <>
                  {g?<div className='time_box'>
                   <div className='time_item'>{time}</div>
                  </div>:<></>}<div key={key} className='chat_massege other_massege'>
                   
                <div className='avatar'>
         <div className='avatar_box' style={{backgroundImage:`url(${open_chat.other_user!==null && open_chat.other_user.main_image!==null?open_chat.other_user.main_image:open_chat.other_user.gender=='male'?man_icon:woman_icon})`}}></div></div>
         <div onClick={()=>{delete_bol_func(item.id, item.file===null?"text":item.file.indexOf('.mp4')!==-1 || item.file.indexOf('.webm')!==-1?"video":"image")}} className='message_text'>
         
         {item.id===deleted_bol && !item.liked?<><div onClick={()=>{likeChat(item.id, item.liked)}} className='like_chat'><FaRegHeart/></div></>:<></>} 
         
         {item.file===null?item.content:item.file.indexOf('.mp4')!==-1 || item.file.indexOf('.webm')!==-1?
         <div className='chat_video_chat' id={key===playingIndex?"play_video":""}>
         {video_links[key]?<>
          {video_links[key]==1?<div className='video_load'><PuffLoader   size={60} color="#FF6865" /></div>:<></>}
          <div onClick={()=>{playVideoFunc(key)}}  className='play_video_box'></div>
        <ReactPlayer
        controls={false}
        onReady={()=>{handleVideoStart(key)}}
        config={{
          file: { forceHLS: false },
          attributes: { playsInline: true }
        }}
  className="video_ch"
  playing={video_keys[key]}
  url={item.file}
  ref={(el) => (videoRefs.current[key] = el)}
/>
         </>:<div onClick={()=>{editVideoLink(key)}} className='video_download'></div>}
       
        
       
         </div>
         :<div className='chat_image'><Image  preview={{ mask: false,  movable: false  }} movable={false} className='chat_image_image' src={item.file}/></div>}
         {item.liked? <span onClick={()=>{likeChat(item.id, item.liked)}} className='like_icon'><FaHeart /></span>:<></>}
         <p className='chat_time'> 
          {getTime(item.timestamp)}
         
         </p>
         </div>
                </div></>)
               }
            }
               
           })}
            
            {[...un_read].length!==0?<button className='scroll_btn' onClick={scrollToBottom}><FaArrowDown /></button>:<></>}
            
            </div>
            <div  onClick={()=>{setopen_active(false)}} className='chat_form'>
             {open_chat.blocked_me || open_chat.me_blocked?<div className='blocked_text'>
              {open_chat.blocked_me?t("blocked_me"):t("me_blocked")}
             </div>:need_activation?<Link className='btn_active' to={'/cabinet/plans/'+open_chat.id+"/"+user.id+"/"+open_chat.name}>{t('activate_plan')}</Link>:<>
             <div className='chat_plus_icon' onClick={()=>{setopen_btns(!open_btns)}}>
             <BsPlusCircleFill />
             </div>
             <div className={`chat_btn_div ${open_btns?"active_btns_box":""}`}>
             <button className='image_btn'>
              {sended_images?<div className='loadBtn'><PuffLoader color='white' size={30} /></div>:<input accept="image/png, image/jpeg, image/jpg" type='file'
               onChange={(e)=>{sendImage(e.target.files[0])}} />}
              <FaImage className='ic' /></button>
              <button className='video_btn' onClick={()=>{
                if(!sended_video){
                  openVideoModal()
                }
                }}>
              {sended_video?<div className='loadBtn'><PuffLoader   color='white' size={30} /></div>:<FaVideo />}</button>
             </div>
             <input className='chat_input' onKeyDown={(event)=>{
              if (event.key === "Enter") {event.preventDefault();sendMessage()}
             }} value={message} onChange={(e)=>{  sendJsonMessage({
                     type: "typing",
                     typing:e.target.value.length!==0
                   }); setMessage(e.target.value)}} placeholder={t("placeholder_chat")}/>
             <div className='chat_btns'>
             
             <button className='send_btn' onClick={sendMessage}><FaTelegramPlane /></button>
             </div>
             </>}
             </div>
            
        </div>:<></>}
        {contextHolder}
    </>
  )
}
